@import '../../theme/colors';
@import '../../theme/spacing';
@import '../../theme/border';
@import '../../theme/mixins/background-text-color';

.chip {
  display: inline-block;
  padding: $spacing-3xs $spacing-xxs;
  border-radius: $border-radius-s;
}

.clickable {
  cursor: pointer;
}

@each $color-name, $color-value in $colors {
  .bg-#{'' + $color-name} {
    @include background-text-color($color-value);
  }
}
